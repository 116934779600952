import React, { useState, useEffect } from 'react';

const Countdown = () => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    let interval;

    const calculateCountdown = () => {
      // Set the target date for the countdown
      const targetDate = new Date('2025-12-31T23:59:59').getTime();

      // Update the countdown every second
      interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = targetDate - now;

        // Calculate days, hours, minutes, and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Set the countdown string
        setCountdown(
          `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`,
        );

        // If the countdown is over, clearInterval
        if (distance < 0) {
          clearInterval(interval);
          setCountdown('Countdown expired');
        }
      }, 1000);
    };

    calculateCountdown();

    // Clean up the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="font-roboto text-center">
      <p className="text-xs mb-5">
        <span className="font-mono font-bold text-4xl md:text-5xl lg:text-8xl">
          {countdown.split(' ')[0]}
        </span>
        <br className=" lg:inline" /> DAYS
      </p>
      <p className="text-xs mb-5">
        <span className="font-mono font-bold text-xl md:text-4xl lg:text-6xl ml-1 md:ml-2 mr-1 md:mr-2">
          {countdown.split(' ')[2]}
        </span> 
        HOURS
        <span className="font-mono font-bold text-xl md:text-4xl lg:text-6xl ml-1 md:ml-2 mr-1 md:mr-2">
          {countdown.split(' ')[4]}
        </span> 
        MINUTES
        <span className="font-mono font-bold text-xl md:text-4xl lg:text-6xl ml-1 md:ml-2 mr-1 md:mr-2">
          {countdown.split(' ')[6]}
        </span> 
        SECONDS
      </p>
      <p className="text-xs mb-5">UNTIL</p>
      <p className="mb-10 text-xl md:text-5xl text-gray-500">Aladdia Platform Launch</p>
      <p className="mb-5 text-xs md:text-base text-gray-500 italic">
        The Aladdia Platform isn’t just about delivering content; it’s about
        creating a cultural and economic ecosystem that benefits creators,
        users, and the global community.
      </p>
      <p className="text-sm md:text-base text-gray-500 italic">
        – Aladdin Entertainment –
      </p>
    </div>
  );
};

export default Countdown;
