export const companyLocation = [
  {
    name: 'HEADQUARTERS',
    latLng: [1.3521, 103.8198],
    phoneNumber: '+82 10 8589 8889',
    email: 'jin@aladdia.com',
    detailedLocation: '15 BEACH ROAD #05-08 BEACH CENTRE SINGAPORE 189677',
  },

  {
    name: 'SEOUL BRANCH',
    latLng: [35.9078, 127.7669],
    phoneNumber: '+82 10 8589 8889',
    email: 'jin@aladdia.com',
    detailedLocation: '3f 82, World Cup Bukro 6Gil, Mapogu, Seoul, Republic of Korea',
  },
  {
    name: 'QATAR BRANCH',
    latLng: [25.276987, 51.520069],
    phoneNumber: '',
    email: '',
    detailedLocation: '',
  },
  {
    name: 'DUBAI BRANCH',
    latLng: [25.276987, 55.296249],
    phoneNumber: '',
    email: '',
    detailedLocation: '',
  },
];
