import React, { useState, useEffect } from 'react';
import { fetchVideoUrls } from '../firebase';
import { LuChevronDownCircle } from 'react-icons/lu';
import { useScrollSections, ScrollingDots } from '../components/ScrollingDots';
import VideoModal from '../components/VideoModal';

const Business = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const numSections = 2;
  const { snapContainerRef, currentSection, scrollToSection } =
    useScrollSections(numSections);
  const sections = Array(numSections).fill(null);

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const urls = await fetchVideoUrls();
        setVideoUrls(urls);
        setLoading(false);
      } catch (error) {
        console.error('Failed to load video URLs', error);
      }
    };
    loadVideos();
  }, []);

  const openModal = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  return (
    <div className="relative">
      <div className="h-screen snap-container" ref={snapContainerRef}>
        <section className="snap-section bg-guardians bg-center bg-cover relative">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="flex flex-col items-center justify-center top-1/4 mobile-landscape:top-20 md:p-8 lg:p-8 xl:p-8 fade-in relative text-center space-y-5">
            <h1 className="text-white text-4xl md:text-6xl mobile-landscape:text-6xl font-bold mb-6">
              GUARDIANS PROJECT
            </h1>
            <div className="flex flex-col md:w-2/3 items-center space-y-5 mx-4 md:mx-0">
              <p className="text-gray-200 text-sm md:text-lg mobile-landscape:text-sm font-bold text-center mb-4">
                The Guardian Project is a fictional superhero squad created by
                Stan Lee for Guardian Media Entertainment, in conjunction with
                the National Hockey League. Each NHL Franchise is represented by
                one of the 30 heroes, titled "Guardians". They are all named in
                accordance with the name of the team (e.g. The Flame for the
                Calgary Flames).
              </p>
              <button
                className="bg-yellow-500 bg-opacity-50 hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded w-32 mt-2"
                style={{ transition: 'background-color 0.3s ease' }}
                onClick={() => openModal(videoUrls[4])}
              >
                Watch Video
              </button>
            </div>
          </div>
          {currentSection === 0 && (
            <LuChevronDownCircle className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl mobile-landscape:hidden" />
          )}
        </section>

        <section className="snap-section bg-aladdia bg-center bg-cover relative">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="flex flex-col items-center justify-center top-1/4 mobile-landscape:top-20 md:p-8 lg:p-8 xl:p-8 fade-in relative text-center space-y-5">
          <h1 className="text-white text-4xl md:text-6xl mobile-landscape:text-6xl font-bold mb-6">
              ALADDIA PROJECT
            </h1>
            <div className="flex flex-col md:w-2/3 items-center space-y-5 mx-4 md:mx-0">
            <p className="text-gray-200 text-sm md:text-lg mobile-landscape:text-sm font-bold text-center mb-4">
                The Aladdia Platform is anticipated to perform well not only in
                the Middle East, Korea, and China, but all around the world. The
                Aladdia platform will have Music Streaming, Webtoons, Community
                Activities, and E-Commerce, all which Netflix lacks. The Aladdia
                Platform will be a hub of platforms that keeps the users engaged
                and satisfied with our service.
              </p>
              <button
                className="bg-yellow-500 bg-opacity-50 hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded w-32 mt-2"
                style={{ transition: 'background-color 0.3s ease' }}
                onClick={() => openModal(videoUrls[5])}
              >
                Watch Video
              </button>
            </div>
          </div>
          {currentSection === 1 && (
            <LuChevronDownCircle className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl hidden" />
            )}
        </section>
      </div>
      <ScrollingDots
        currentSection={currentSection}
        sections={sections}
        scrollToSection={scrollToSection}
      />
      {isModalOpen && (
        <VideoModal onClose={closeModal}>
          <video
            className="w-4/5 h-full object-contain"
            controls
            autoPlay
            src={selectedVideo}
            type="video/mp4"
          />
        </VideoModal>
      )}
    </div>
  );
};

export default Business;
