import React, { useState, useEffect } from 'react';
import { useScrollSections, ScrollingDots } from '../components/ScrollingDots';
import { fetchVideoUrls } from '../firebase';

const Crypto = () => {
  const numSections = 2;
  const { snapContainerRef, currentSection, scrollToSection } =
    useScrollSections(numSections);
  const sections = Array(numSections).fill(null);
  const [videoUrls, setVideoUrls] = useState([]);
  const [showTokenInfo, setShowTokenInfo] = useState(false);

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const urls = await fetchVideoUrls();
        setVideoUrls(urls);
      } catch (error) {
        console.error('Failed to load video URLs', error);
      }
    };
    loadVideos();
  }, []);

  return (
    <div className="relative">
      <div className="snap-container bg-[#1c224d]" ref={snapContainerRef}>
        <section className="snap-section relative flex justify-center items-center">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[18]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 flex justify-center items-center p-4 bg-black bg-opacity-50">
            <div className="text-center italic md:w-2/3 lg:w-1/2 mobile-landscape:w-2/3 fade-in">
              <h1 className="text-white text-3xl md:text-5xl lg:text-6xl font-bold mb-8">
                VEVE Partnership
              </h1>
              <p className="text-white text-lg md:text-2xl lg:text-3xl mb-6">
                We have a partnership with VEVE in creating character,
                merchandise, and other NFT products, such as our own Metaverse
                real-estate with locally-adapted themes.
              </p>
              <p className="text-white text-lg md:text-2xl lg:text-3xl">
                With VR, AR, XR, and Metaverse technology, we will be able to
                show people fantastical universes as well as the local world of
                each participating Nation.
              </p>
            </div>
          </div>
        </section>

        <section className="snap-section relative flex justify-center items-center">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[19]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 flex flex-col justify-center items-center p-4 bg-black bg-opacity-50">
            <h1 className="text-white text-3xl md:text-5xl lg:text-6xl font-bold mb-8 fade-in">
              Token Ecosystem
            </h1>
            <button
              onClick={() => setShowTokenInfo(!showTokenInfo)}
              className="bg-yellow-500 bg-opacity-50 hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded w-32 mb-4 fade-in"
              style={{ transition: 'background-color 0.3s ease' }}
                          >
              {showTokenInfo ? 'Hide Details' : 'Read More'}
            </button>
            {showTokenInfo && (
              <div className="text-white text-lg md:text-2xl lg:text-3xl mobile-landscape:text-lg px-4 mobile-landscape:px-20 md:px-0 max-w-7xl overflow-y-auto h-1/2 mx-auto text-center leading-relaxed fade-in">
                <p className="mb-6">
                  The Aladdia project development team is pioneering the
                  creation of an E-Wallet, leveraging a fusion of ICT and
                  Blockchain technologies. This innovative E-Wallet will offer
                  convenience, security, and real-time transactions, accessible
                  to users worldwide. This represents a groundbreaking evolution
                  in the OTT E-Commerce market, bringing us closer to the
                  creation of a new economic ecosystem that we aspire to build.
                </p>
                <p className="mb-6">
                  We will then issue ADC tokens to facilitate convenient access
                  for global users to our upcoming OTT platform. Users worldwide
                  will be able to use ADC tokens to access our platform and
                  purchase content anytime, anywhere in real-time.
                </p>
                <p className="mb-6">
                  The Aladdia Wallet and ADC provides OTT platform & Live
                  content (Music & Sport), Webtoon & Gaming, E-Commerce,
                  Metaverse & NFT marketplace services. Through SNS messaging
                  service and real-time Fin-tech (blockchain-based payment
                  system) service, purchasing and payments for service contents
                  are conducted safely and conveniently. All transactions are
                  facilitated using ADC Tokens to activate the circulation of
                  ADC Tokens. Various points provided as awards can be exchanged
                  for ADC Tokens and used as a payment method for content
                  utilization on the platform and E-Commerce shopping.
                  Additionally, ADC can be cashed out through exchanges.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
      <ScrollingDots
        currentSection={currentSection}
        sections={sections}
        scrollToSection={scrollToSection}
      />
    </div>
  );
};

export default Crypto;
