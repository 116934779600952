import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ isOpen, onClose }) => {
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div
      ref={sidebarRef}
      className={`fixed top-0 right-0 h-full bg-gray-500 text-white py-6 px-4 flex flex-col items-center justify-center transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'} w-3/4 md:w-1/3`}
    >
      <button
        className="absolute top-8 right-0 px-8 mobile-landscape:px-12 md:px-14 lg:px-20 text-white"
        onClick={onClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <ul className="space-y-4 w-full mobile-landscape:space-y-0">
        <li className="w-full">
          <Link
            to="/"
            className={`block py-2 px-4 rounded transition-colors duration-300 ease-in-out hover:text-yellow-500 ${location.pathname === '/' ? 'text-yellow-500 font-extrabold text-xl mobile-landscape:text-2xl md:text-4xl lg:text-5xl' : 'text-white text-xl md:text-2xl lg:text-4xl'}`}
            onClick={onClose}
          >
            Home
          </Link>
        </li>
        {[
          'Our Team',
          'IP Business & Projects',
          'Media',
          'Cryptoverse & NFT',
          'Technology',
          'Contact Us',
        ].map((item, index) => {
          const path = item.toLowerCase().replace(/ /g, '');
          return (
            <li key={index} className="w-full">
              <Link
                to={`/${path}`}
                className={`block py-2 px-4 rounded transition-colors duration-300 ease-in-out hover:text-yellow-500 ${location.pathname === `/${path}` ? 'text-yellow-500 font-extrabold text-xl mobile-landscape:text-2xl md:text-4xl lg:text-5xl' : 'text-white text-xl md:text-2xl lg:text-4xl'}`}
                onClick={onClose}
              >
                {item}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
