import React from 'react';
import WorldMap from '../components/WorldMap';
import { companyLocation } from '../components/Countries';

const ContactUs = () => {
  return (
    <div className="relative bg-[#1c224d]">
      <section className="text-center p-5">
        <h1 className="text-4xl md:text-6xl font-bold my-5 text-white mt-20 fade-in">CONTACT US</h1>
        <div className="w-full h-[50vh] md:h-[60vh] lg:h-[70vh] py-10 fade-in">
          <WorldMap />
        </div>
        <div className="text-lg text-white grid md:grid-cols-2 gap-4">
          {companyLocation.map((location, index) => (
            <div key={index} className="mb-4 flex justify-center fade-in">
              <div className="border-2 border-yellow-500 p-4 w-full md:w-4/5">
                <h2 className="text-2xl md:text-4xl mb-6 md:mb-10 font-bold">{location.name}</h2>
                <p className="mb-3">{location.detailedLocation}</p>
                <p className="mb-3">Email: {location.email}</p>
                <p className="mb-3">Phone: {location.phoneNumber}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
