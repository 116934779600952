// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/HomePage';
import BusinessPage from './pages/Business';
import MediaPage from './pages/Media';
import OurTeamPage from './pages/OurTeam';
import CryptoPage from './pages/Crypto';
import TechnologiesPage from './pages/Technologies';
import Header from './components/Header';
import ContactUs from './pages/ContactUs';
import PasswordProtection from './components/PaswordProtection';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = () => {
      const timestamp = localStorage.getItem('authTimestamp');
      if (timestamp) {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - timestamp;
        const twentyMinutesInMillis = 10 * 60 * 1000;
        if (timeDifference <= twentyMinutesInMillis) {
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('authTimestamp');
        }
      }
    };

    checkAuthentication();
  }, []);

  const handleAuthentication = () => {
    setIsAuthenticated(true);
  };


  return (
    <Router>
      {isAuthenticated ? (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/ipbusiness&projects" element={<BusinessPage />} />
            <Route path="/media" element={<MediaPage />} />
            <Route path="/ourteam" element={<OurTeamPage />} />
            <Route path="/cryptoverse&nft" element={<CryptoPage />} />
            <Route path="/technology" element={<TechnologiesPage />} />
            <Route path="/contactus" element={<ContactUs />} />
          </Routes>
        </>
      ) : (
        <PasswordProtection onAuthenticated={handleAuthentication} />
      )}
    </Router>
  );
}

export default App;
