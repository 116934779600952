import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

// Function to fetch video URLs
export const fetchVideoUrls = async () => {
  try {
    const urls = await Promise.all([
      getDownloadURL(ref(storage, 'HomePageFrontMediaV5(LQ).mp4')),
      getDownloadURL(ref(storage, 'WebReelV3 (LQ).mp4')),
      getDownloadURL(ref(storage, 'GuardiansWebReelV4 (LQ).mp4')),
      getDownloadURL(ref(storage, 'Virtual Studios WebReelV4 (LQ).mp4')),
      getDownloadURL(ref(storage, 'StanLeeGuardiansIntroV4 (LQ).mp4')),
      getDownloadURL(ref(storage, "Aladdia_TentpoleV1 (LQ).mp4")),
      getDownloadURL(ref(storage, "GalleryVid1.mp4")),
      getDownloadURL(ref(storage, "GalleryVid2.mp4")),
      getDownloadURL(ref(storage, "GalleryVid3.mp4")),
      getDownloadURL(ref(storage, "GalleryVid4.mp4")),
      getDownloadURL(ref(storage, "GalleryVid5.mp4")),
      getDownloadURL(ref(storage, "GalleryVid6.mp4")),
      getDownloadURL(ref(storage, "GalleryVid7.mp4")),
      getDownloadURL(ref(storage, "GalleryVid8.mp4")),
      getDownloadURL(ref(storage, "GalleryVid9.mp4")),
      getDownloadURL(ref(storage, "Vive IntroV3 (LQ).mp4")),
      getDownloadURL(ref(storage, "VirtualProductionPadori WebReelV2 (LQ).mp4")),
      getDownloadURL(ref(storage, "VirtualProductionMarsWebReelV2 (LQ).mp4")),
      getDownloadURL(ref(storage, "CryptoBack V3.mp4")),
      getDownloadURL(ref(storage, "Holo Interface V4.mp4")),
      getDownloadURL(ref(storage, "Times Square, NYC Loop v3 (MQ).mp4")),

    ]);
    return urls;
  } catch (error) {
    console.error('Error fetching video URLs:', error.message);
    throw error;
  }
};
