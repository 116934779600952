// src/components/PasswordProtection.js
import React, { useState, useEffect } from 'react';
import logo from '../assets/aladdin_logo.png';
import '../index.css'; // Import the CSS file

const PasswordProtection = ({ onAuthenticated }) => {
  const [password, setPassword] = useState('');
  const correctPassword = 'aladdin5'; // Set your password here
  const [showPasswordBox, setShowPasswordBox] = useState(false); // State to control the visibility of the password box

  useEffect(() => {
    // Set a timeout to show the password box after a delay
    const timeout = setTimeout(() => {
      setShowPasswordBox(true);
    }, 500); // Adjust the delay as needed

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (password === correctPassword) {
      const timestamp = new Date().getTime();
      localStorage.setItem('authTimestamp', timestamp);
      onAuthenticated(); // Notify parent component about successful authentication
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#1c224d]">
      <div className={`p-8 bg-slate-100 shadow-md rounded w-full max-w-sm md:max-w-md h-72 md:h-72 flex flex-col justify-between fade-in ${showPasswordBox ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="h-8 mx-auto mb-2 md:mb-4" />
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter password"
          className="w-full px-4 py-2 mb-2 md:mb-4 border rounded bg-white focus:bg-white focus:border-blue-500 focus:outline-none transition-all duration-300"
        />
        <button
          onClick={handleSubmit}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-all duration-300"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PasswordProtection;
