import React from 'react';

const VideoPlayer = ({ src }) => {
  return (
    <div className="flex justify-center items-center h-full">
      <video
        src={src}
        controls
        className="max-w-full max-h-full"
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </div>
  );
};

export default VideoPlayer;
