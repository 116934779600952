import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const VideoModal = ({ onClose, children }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative bg-transparent rounded-lg p-4 w-full h-full">
        <button
          className="absolute top-4 right-4 text-4xl text-white hover:text-gray-300"
          onClick={onClose}
        >
          <AiOutlineClose />
        </button>
        <div className="w-full h-full flex items-center justify-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
