import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';

const MemberModal = ({ isOpen, onClose, member }) => {
  const [modalOpen, setModalOpen] = useState(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
    if (isOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = 'auto'; 
    }
  }, [isOpen]);

  const handleClose = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div className={`fixed inset-0 bg-[#1c224d] bg-opacity-80 flex justify-center items-center z-50 ${modalOpen ? 'slide-in' : 'slide-out'}`}>
      <div className="bg-slate-100 p-8 rounded-lg md:w-4/5 lg:w-4/5 xl:w-4/5 w-full h-4/5 relative flex flex-col sm:flex-row overflow-hidden">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 rounded-full"
          onClick={handleClose}
        >
          <IoClose className="text-2xl" />
        </button>
        <div className="w-full sm:w-2/5 flex flex-col justify-center items-center p-4 md:overflow-y-auto lg:overflow-hidden xl:overflow-hidden">
          <img
            src={member.image}
            alt={`${member.name} profile`}
            className="w-1/4 md:w-1/2 lg:w-1/2 xl:w-1/2 h-auto rounded-lg mb-4"
          />
          <div className="text-center">
            <h2 className="text-lg md:text-2xl lg:text-2xl xl:text-2xl font-bold mb-2">
              {member.name}
            </h2>
            <h3 className="text-lg md:text-xl lg:text-xl xl:text-xl text-yellow-500 mb-2">
              {member.position.split('  ').map((pos, i) => (
                <span key={i} className="block">
                  {pos}
                </span>
              ))}
            </h3>
            <p className="text-xs md:text-sm lg:text-sm xl:text-sm mb-4">
              {member.description}
            </p>
          </div>
        </div>
        <div className="w-full md:w-3/5 lg:w-3/5 xl:w-3/5 p-4 flex flex-col justify-center overflow-y-auto">
        {member.record && (
            <div className="overflow-y-auto">
              {member.record.data.map((record, index) => {
                if (record.type === 'text') {
                  return (
                    <p key={index} className="text-xs md:text-base lg:text-base xl:text-base mb-4">
                      {record.content}
                    </p>
                  );
                } else if (record.type === 'image') {
                  return (
                    <img
                      key={index}
                      src={record.content}
                      alt={`Record ${index}`}
                      className="w-full mb-4"
                    />
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberModal;
