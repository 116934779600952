import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
import React from 'react';
import { companyLocation } from './Countries';

const WorldMap = () => {
  const renderMarkerTooltip = (event, label, code) => {
    const locationInfo = companyLocation.find((location) => location.name === label.text());

    if (locationInfo) {
      const { name, phoneNumber, email, detailedLocation } = locationInfo;

      return label.html(`
        <div style="background-color: white; border-radius: 6px; min-height: 100px; width: 200px; color: black !important; padding: 10px;">
          <p style="color: black !important;"><b>${name}</b></p>
          <p>${phoneNumber}</p>
          <p>${email}</p>
          <p>${detailedLocation}</p>
        </div>
      `);
    }
  };

  return (
    <div style={{ margin: 'auto', width: '100%', height: '100%' }}>
      <VectorMap
        map={worldMill}
        containerStyle={{
          width: '100%',
          height: '100%',
        }}
        backgroundColor="#bcd2e8"
        markers={companyLocation}
        markerStyle={{
          initial: {
            fill: 'red',
            r: 8, // Change the size of the marker circle here (default is 10)
          },
        }}
        onMarkerTipShow={renderMarkerTooltip}
      />
    </div>
  );
};

export default WorldMap;
