import React, { useState, useEffect } from 'react';
import { useScrollSections, ScrollingDots } from '../components/ScrollingDots';
import VideoPlayer from '../components/VideoPlayer';

import boyband1 from '../assets/Boy Group/BoyBand1 (Large).png';
import boyband2 from '../assets/Boy Group/BoyBand2 (Large).png';
import boyband3 from '../assets/Boy Group/BoyBand3 (Large).png';
import boyband4 from '../assets/Boy Group/BoyBand4 (Large).png';
import boyband5 from '../assets/Boy Group/BoyBand5 (Large).png';
import boyband6 from '../assets/Boy Group/BoyBand6 (Large).png';
import boyband7 from '../assets/Boy Group/BoyBand7 (Large).png';
import boyband8 from '../assets/Boy Group/BoyBand8 (Large).png';
import boyband9 from '../assets/Boy Group/BoyBand9 (Large).png';
import boyband10 from '../assets/Boy Group/BoyBand10 (Large).png';
import boyband11 from '../assets/Boy Group/BoyBand11 (Large).png';
import boyband12 from '../assets/Boy Group/BoyBand12 (Large).png';
import boyband13 from '../assets/Boy Group/BoyBand13 (Large).png';
import boyband14 from '../assets/Boy Group/BoyBand14 (Large).png';
import girlgroup1 from '../assets/Girl Group/GirlGroup1 (Large).png';
import girlgroup2 from '../assets/Girl Group/GirlGroup2 (Large).png';
import girlgroup3 from '../assets/Girl Group/GirlGroup3 (Large).png';
import girlgroup4 from '../assets/Girl Group/GirlGroup4 (Large).png';
import girlgroup5 from '../assets/Girl Group/GirlGroup5 (Large).png';
import girlgroup6 from '../assets/Girl Group/GirlGroup6 (Large).png';
import girlgroup7 from '../assets/Girl Group/GirlGroup7 (Large).png';
import girlgroup8 from '../assets/Girl Group/GirlGroup8 (Large).png';
import { fetchVideoUrls } from '../firebase';

const Media = () => {
  const numSections = 2;
  const { snapContainerRef, currentSection, scrollToSection } =
    useScrollSections(numSections);
  const sections = Array(numSections).fill(null);
  const [videoUrls, setVideoUrls] = useState([]);

  const [boyBandIndex, setBoyBandIndex] = useState(0);
  const [girlGroupIndex, setGirlGroupIndex] = useState(0);
  const [boyBandImageLoaded, setBoyBandImageLoaded] = useState(false);
  const [girlGroupImageLoaded, setGirlGroupImageLoaded] = useState(false);

  const boyBandImages = [
    boyband1,
    boyband2,
    boyband3,
    boyband4,
    boyband5,
    boyband6,
    boyband7,
    boyband8,
    boyband9,
    boyband10,
    boyband11,
    boyband12,
    boyband13,
    boyband14,
  ];
  const girlGroupImages = [
    girlgroup1,
    girlgroup2,
    girlgroup3,
    girlgroup4,
    girlgroup5,
    girlgroup6,
    girlgroup7,
    girlgroup8,
  ];

  const totalBoyBandImages = boyBandImages.length;
  const totalGirlGroupImages = girlGroupImages.length;

  useEffect(() => {
    const boyBandTimer = setInterval(() => {
      setBoyBandIndex((prevIndex) => (prevIndex + 1) % totalBoyBandImages);
      setBoyBandImageLoaded(false);
    }, 5000); // Change interval as needed for the animation speed

    const girlGroupTimeout = setTimeout(() => {
      const girlGroupTimer = setInterval(() => {
        setGirlGroupIndex(
          (prevIndex) => (prevIndex + 1) % totalGirlGroupImages,
        );
        setGirlGroupImageLoaded(false);
      }, 5000); // Change interval as needed for the animation speed

      return () => clearInterval(girlGroupTimer);
    }, 1000); // 1 second delay before starting the girl group timer

    return () => {
      clearInterval(boyBandTimer);
      clearTimeout(girlGroupTimeout);
    };
  }, [totalBoyBandImages, totalGirlGroupImages]);

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const urls = await fetchVideoUrls();
        setVideoUrls(urls);
      } catch (error) {
        console.error('Failed to load video URLs', error);
      }
    };
    loadVideos();
  }, []);

  return (
    <div className="relative bg-[#1c224d]">
      <div className="snap-container" ref={snapContainerRef}>
        {/* <section className="snap-section flex justify-center items-center h-screen">
          {currentSection === 0 && (
            <div className="flex flex-col sm:flex-row md:flex-row justify-between w-full h-full">
              <div className="w-full h-1/2 sm:h-full md:h-full sm:w-1/2 md:w-1/2 flex items-center justify-center">
                <div className="relative w-full h-full">
                  <img
                    src={boyBandImages[boyBandIndex]}
                    alt="Boy Band Image"
                    className={`w-full h-full object-fit shadow-md ${boyBandImageLoaded ? 'slide-in' : ''}`}
                    onLoad={() => setBoyBandImageLoaded(true)}
                  />
                </div>
              </div>
              <div className="w-full h-1/2 sm:h-full md:h-full sm:w-1/2 md:w-1/2 flex items-center justify-center">
                <div className="relative w-full h-full">
                  <img
                    src={girlGroupImages[girlGroupIndex]}
                    alt="Girl Group Image"
                    className={`w-full h-full object-fit shadow-md ${girlGroupImageLoaded ? 'slide-in' : ''}`}
                    onLoad={() => setGirlGroupImageLoaded(true)}
                  />
                </div>
              </div>
              <h2 className="absolute bottom-0 left-0 right-0 text-center text-white text-4xl font-bold mb-8">
                K-POP
              </h2>
            </div>
          )}
        </section> */}
        <section className="snap-section flex justify-center items-center">
          {currentSection === 0 && (
            <div className="w-full h-4/5 flex justify-center items-center">
              <VideoPlayer src={videoUrls[1]} />
              <h2 className="absolute bottom-0 left-0 right-0 text-center text-white text-4xl font-bold mb-8">
                Movies & Shows
              </h2>
            </div>
          )}
        </section>
        <section className="snap-section relative flex justify-center items-center">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[20]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 flex justify-center items-center p-4 bg-black bg-opacity-50">
            <div className="text-center italic md:w-2/3 lg:w-1/2 mobile-landscape:w-2/3">
              <p className="text-white text-xl md:text-3xl font-bold">
                Aladdia isn't just about delivering content; it's about creating
                a cultural and economic ecosystem that benefits creators, users,
                and the global community.
              </p>
              <p className="text-white text-lg md:text-xl mt-4">
                - Chairman of Aladdin
              </p>
            </div>
          </div>
        </section>
      </div>
      <ScrollingDots
        currentSection={currentSection}
        sections={sections}
        scrollToSection={scrollToSection}
      />
    </div>
  );
};

export default Media;
