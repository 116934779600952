import React, { useState, useEffect } from 'react';
import Countdown from '../components/Countdown';
import { LuChevronDownCircle } from 'react-icons/lu';
import { useScrollSections, ScrollingDots } from '../components/ScrollingDots';
import { useNavigate } from 'react-router-dom';
import { fetchVideoUrls } from '../firebase';

const HomePage = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const numSections = 6;
  const { snapContainerRef, currentSection, scrollToSection } =
    useScrollSections(numSections);
  const sections = Array(numSections).fill(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const urls = await fetchVideoUrls();
        setVideoUrls(urls);
      } catch (error) {
        console.error('Failed to load video URLs', error);
      }
    };
    loadVideos();
  }, []);

  return (
    <div className="relative">
      <div className="snap-container" ref={snapContainerRef}>
        <section className="snap-section relative w-full h-screen">
          <video
            className="background-video"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[1]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          <LuChevronDownCircle className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl" />
        </section>

        <section className="snap-section countdown-section bg-[#1c224d] text-white relative w-full h-screen">
          <Countdown />
        </section>

        <section className="snap-section relative w-full h-screen">
          <video
            className="background-video"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[5]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 2 && (
            <div className="text-white text-center absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <h2 className="text-4xl md:text-6xl lg:text-6xl xl:text-6xl font-bold mt-4">
                Aladdia Platform
              </h2>
              <a
                href="https://ott-platform-1.onrender.com/"
                className="block mt-4 text-white underline hover:text-yellow-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="bg-[#1c224d] bg-opacity-50 hover:bg-opacity-70 text-white font-bold py-2 px-4 rounded"
                  style={{ transition: 'background-color 0.3s ease' }}
                >
                  Visit
                </button>
              </a>
            </div>
          )}
        </section>

        <section className="snap-section relative w-full h-screen">
          <video
            className="background-video"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[2]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 3 && (
            <div className="text-white text-center absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <h2 className="text-4xl md:text-6xl lg:text-6xl xl:text-6xl font-bold mt-4">
                IP Business & Projects
              </h2>
              <button
                className="bg-[#1c224d] bg-opacity-50 hover:bg-opacity-70 text-white font-bold py-2 px-4 rounded mt-4"
                style={{ transition: 'background-color 0.3s ease' }}
                onClick={() => navigate('/ipbusiness&projects')}
              >
                Visit
              </button>
            </div>
          )}
        </section>

        <section className="snap-section relative w-full h-screen">
          <video
            className="background-video"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[3]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 4 && (
            <div className="text-white text-center absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <h2 className="text-4xl md:text-6xl lg:text-6xl xl:text-6xl font-bold mt-4">
                Virtual Studios
              </h2>
              <button
                className="bg-[#1c224d] bg-opacity-50 hover:bg-opacity-70 text-white font-bold py-2 px-4 rounded mt-4"
                style={{ transition: 'background-color 0.3s ease' }}
                onClick={() => navigate('/technology')}
              >
                Visit
              </button>
            </div>
          )}
        </section>

        <section className="snap-section relative w-full h-screen">
          <video
            className="background-video"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[6]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 5 && (
            <div className="text-white text-center absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <h2 className="text-4xl md:text-6xl lg:text-6xl xl:text-6xl font-bold mt-4">
                VFX
              </h2>
              <a
                href="http://vixen-studios.com/kr/"
                className="block mt-4 text-white underline hover:text-yellow-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="bg-[#1c224d] bg-opacity-50 hover:bg-opacity-70 text-white font-bold py-2 px-4 rounded"
                  style={{ transition: 'background-color 0.3s ease' }}
                >
                  Visit
                </button>
              </a>
            </div>
          )}
        </section>
      </div>

      <ScrollingDots
        currentSection={currentSection}
        sections={sections}
        scrollToSection={scrollToSection}
      />
    </div>
  );
};

export default HomePage;
