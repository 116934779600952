import React, { useState, useEffect } from 'react';
import { LuChevronDownCircle } from 'react-icons/lu';
import { FaCirclePlay } from "react-icons/fa6";
import { useScrollSections, ScrollingDots } from '../components/ScrollingDots';
import { fetchVideoUrls } from '../firebase';
import Loading from '../components/Loading';
import VideoModal from '../components/VideoModal';

import thumbnail1 from '../assets/Thumbnails/thumbnail1.png';
import thumbnail2 from '../assets/Thumbnails/thumbnail2.png';
import thumbnail3 from '../assets/Thumbnails/thumbnail3.png';
import thumbnail4 from '../assets/Thumbnails/thumbnail4.png';
import thumbnail5 from '../assets/Thumbnails/thumbnail5.png';
import thumbnail6 from '../assets/Thumbnails/thumbnail6.png';
import thumbnail7 from '../assets/Thumbnails/thumbnail7.png';
import thumbnail8 from '../assets/Thumbnails/thumbnail8.png';
import thumbnail9 from '../assets/Thumbnails/thumbnail9.png';

const Technologies = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const numSections = 5;
  const { snapContainerRef, currentSection, scrollToSection } =
    useScrollSections(numSections);
  const sections = Array(numSections).fill(null);

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const urls = await fetchVideoUrls();
        setVideoUrls(urls);
        setLoading(false);
      } catch (error) {
        console.error('Failed to load video URLs', error);
      }
    };
    loadVideos();
  }, []);

  const openModal = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  const thumbnails = [thumbnail1, thumbnail2, thumbnail3, thumbnail4, thumbnail5, thumbnail6, thumbnail7, thumbnail8, thumbnail9];

  return (
    <div className="relative bg-[#1c224d]">
      <div className="snap-container" ref={snapContainerRef}>
        <section className="snap-section">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[15]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 0 && (
            <LuChevronDownCircle className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl" />
          )}
        </section>

        <section className="snap-section">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[3]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 1 && (
            <LuChevronDownCircle className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl" />
          )}
        </section>

        <section className="snap-section">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[16]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 2 && (
            <LuChevronDownCircle className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl" />
          )}
        </section>

        <section className="snap-section">
          <video
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            src={videoUrls[17]}
            preload="metadata"
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
          {currentSection === 3 && (
            <LuChevronDownCircle className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-white text-4xl" />
          )}
        </section>

        <section className="snap-section md:pt-20 min-h-screen h-auto md:h-screen">
          <div className="flex flex-col items-center justify-center min-h-full">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-1 p-8 w-full max-w-screen-sm md:max-w-full mobile-landscape:py-2">
              {videoUrls.slice(6, 15).map((url, index) => (
                <div
                  key={index}
                  className="relative group w-full max-w-full h-28 md:h-64 lg:h-48 xl:h-64 mobile-landscape:h-20"
                >
                  <img
                    src={thumbnails[index]}
                    alt={`Video ${index + 1}`}
                    className="w-full h-full object-cover cursor-pointer"
                    onClick={() => openModal(url)}
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      onClick={() => openModal(url)}
                      className="text-white text-4xl md:text-8xl"
                    >
                      <FaCirclePlay />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center text-white">
              Click to watch
            </div>
          </div>
        </section>
      </div>

      {isModalOpen && (
        <VideoModal onClose={closeModal}>
          <video
            className="w-4/5 h-full object-contain"
            controls
            autoPlay
            src={selectedVideo}
            type="video/mp4"
          />
        </VideoModal>
      )}

      <ScrollingDots
        currentSection={currentSection}
        sections={sections}
        scrollToSection={scrollToSection}
      />
    </div>
  );
};

export default Technologies;
