// src/components/MemberCard.js
import React from 'react';

const MemberCard = ({ member, onClick }) => {
  return (
    <div
      className="text-center text-white cursor-pointer"
      onClick={() => onClick(member)}
    >
      <div className="flex flex-col justify-start items-center h-full">
        <div className="w-20 xl:w-64 lg:w-40 md:w-32 mobile-landscape:w-20 max-w-sm h-20 md:h-32 lg:h-40 xl:h-64 mobile-landscape:h-20 flex justify-center items-center mb-4 rounded-lg border border-yellow-500 overflow-hidden">
          <img
            src={member.image}
            alt={`${member.name} profile`}
            className="w-full h-full object-fill"
          />
        </div>
        <h2 className="text-xs xl:text-xl lg:text-xl md:text-xl mobile-landscape:text-xs font-bold mb-2">{member.name}</h2>
        <h3 className="text-xs xl:text-lg lg:text-lg md:text-md text-yellow-500 mb-2">
          {member.position.split('  ').map((pos, i) => (
            <span key={i} className="block">
              {pos}
            </span>
          ))}
        </h3>
      </div>
    </div>
  );
};

export default MemberCard;
