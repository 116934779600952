import React, { useRef, useState, useEffect } from 'react';

// Custom Hook
const useScrollSections = (numSections) => {
  const snapContainerRef = useRef(null);
  const [currentSection, setCurrentSection] = useState(0);

  const scrollToSection = (index) => {
    if (snapContainerRef.current) {
      const sectionHeight = snapContainerRef.current.offsetHeight;
      snapContainerRef.current.scrollTo({
        top: index * sectionHeight,
        behavior: 'smooth',
      });
      setCurrentSection(index);
    }
  };

  const handleScroll = () => {
    if (snapContainerRef.current) {
      const sectionHeight = snapContainerRef.current.offsetHeight;
      const scrollTop = snapContainerRef.current.scrollTop;
      const newSection = Math.round(scrollTop / sectionHeight);
      if (newSection !== currentSection) {
        setCurrentSection(newSection);
      }
    }
  };

  useEffect(() => {
    const container = snapContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [currentSection]);

  return { snapContainerRef, currentSection, scrollToSection };
};

// ScrollingDots Component
const ScrollingDots = ({ currentSection, sections, scrollToSection }) => {
  return (
    <div className="absolute top-1/2 transform -translate-y-1/2 right-5 flex flex-col gap-2">
      {sections.map((_, index) => (
        <button
          key={index}
          className={`dot ${currentSection === index ? 'bg-yellow-500' : 'bg-white'}`}
          onClick={() => scrollToSection(index)}
        ></button>
      ))}
    </div>
  );
};

export { useScrollSections, ScrollingDots };
